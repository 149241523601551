import { SetExpenseCategories, Actions } from './actions';
import { ExpenseCategoriesState } from './types';

const initialState: ExpenseCategoriesState = {
  ids: [],
};

const expenseCategoriesReducer = (
  state: ExpenseCategoriesState = initialState,
  action: SetExpenseCategories = null,
): ExpenseCategoriesState => {
  switch (action?.type) {
    case Actions.SET_EXPENSE_CATEGORIES:
      return {
        ...state,
        ids: action.ids,
      };

    default:
      return state;
  }
};
export default expenseCategoriesReducer;
