import Select, {
  components as reactSelectComponents,
  MultiValueRemoveProps,
  ActionMeta,
} from 'react-select';
import matchesProperty from 'lodash/matchesProperty';
import find from 'lodash/find';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import prop from 'lodash/property';
import identity from 'lodash/identity';
import { withTheme, SelectBoxProps, Option } from './utils';

type OptionValue = null | Option | Option[];

const SelectBox = ({
  autoFocus,
  components,
  className,
  defaultValue,
  disabled,
  filterOption,
  getOptionLabel,
  getOptionValue,
  id,
  inputId,
  isClearable,
  isLoading,
  isSearchable = true,
  isOptionSelected,
  isOptionDisabled,
  isMulti,
  onFocus,
  menuPlacement,
  name,
  onKeyDown,
  placeholder,
  styles,
  theme,
  onBlur,
  menuPortalTarget,
  hideSelectedOptions,
  controlShouldRenderValue,
  closeMenuOnSelect,
  inputValue,
  onInputChange,
  onChange,
  options,
  value,
  valueKey = 'value',
  sortBy = 'label',
  sortInverted = false,
  sorted = true,
  onMenuOpen,
  onMenuClose,
  defaultOption = null,
  selectedOnTop = true,
  menuIsOpen,
}: SelectBoxProps) => {
  const getValue = (): OptionValue => {
    if (isNil(value)) {
      return defaultOption;
    }
    if (isMulti) {
      return filter(options, (option: any) => value.includes(option[valueKey]));
    }
    return find(options, matchesProperty(valueKey, value)) || null;
  };

  const handleOnChange = (
    selectedValue: any,
    actionMeta: ActionMeta<Option>,
  ) => {
    if (!onChange) return;

    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        // Prevent removing a fixed option by using the keyboard
        if (!actionMeta.removedValue || actionMeta.removedValue.isFixed) return;
        break;
      default:
    }

    let changedValue: OptionValue | OptionValue[];
    if (isMulti) {
      changedValue = (selectedValue || []).map(
        valueKey ? prop(valueKey) : identity,
      );
    } else {
      changedValue =
        selectedValue && valueKey
          ? get(selectedValue, valueKey)
          : selectedValue;
    }
    onChange(changedValue);
  };

  const getOptions = () => {
    let sortedOptions = options;
    if (sorted) {
      sortedOptions = options.sort((a, b) => {
        const aVal = a[sortBy];
        const bVal = b[sortBy];
        if (aVal < bVal) {
          return sortInverted ? 1 : -1;
        }
        if (aVal > bVal) {
          return sortInverted ? -1 : 1;
        }
        return 0;
      });
    }

    // If a value is selected, force it to be displayed at the top
    const selectedOption = sortedOptions.find((o) => o.value === value);
    if (selectedOption && selectedOnTop) {
      sortedOptions = [
        selectedOption,
        ...sortedOptions.filter((o) => o.value !== value),
      ];
    }

    return sortedOptions;
  };

  return (
    <Select
      autoFocus={autoFocus}
      className={className}
      components={{
        ...components,
        // Overload the MultiValueRemove component so we can hide the close button if the option is fixed
        MultiValueRemove: (props: MultiValueRemoveProps<Option, true>) => {
          const { data, selectProps } = props;
          if (data.isFixed || selectProps.isDisabled) return null;

          return (
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            <reactSelectComponents.MultiValueRemove {...props} />
          );
        },
      }}
      defaultValue={defaultValue}
      isDisabled={disabled}
      isOptionSelected={isOptionSelected}
      isOptionDisabled={isOptionDisabled}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      inputId={inputId}
      name={name}
      id={id}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isLoading={isLoading}
      isMulti={isMulti}
      onBlur={onBlur}
      onChange={handleOnChange}
      menuPlacement={menuPlacement}
      onFocus={onFocus}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      options={getOptions()}
      filterOption={filterOption}
      styles={styles}
      theme={theme}
      value={getValue()}
      menuPortalTarget={menuPortalTarget}
      hideSelectedOptions={hideSelectedOptions}
      controlShouldRenderValue={controlShouldRenderValue}
      closeMenuOnSelect={closeMenuOnSelect}
      inputValue={inputValue}
      onInputChange={onInputChange}
      classNamePrefix="select-box"
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      menuIsOpen={menuIsOpen}
    />
  );
};

export default withTheme(SelectBox);
